.clientsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
}

.clientsContainer p {
  display: flex;
  align-self: center;
  font-size: 18px;
}

.clientTitle {
  display: flex;
  align-self: center;
  margin-top: 80px;
  font-weight: 800;
  font-size: 40px;
}

.clientSubtitle {
  font-family: Arial, Helvetica, sans-serif;
}

.avaliationContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avaliationList {
  display: flex;
  list-style: none;
  margin-top: 70px;
}

.avaliationList p {
  width: 70%;
  text-align: start;
  font-size: 20px;
  margin-top: 20px;
}

.avaliationList h3 {
  margin-top: 10px;
  margin-left: 20px;
}

.avaliationList li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}

.avatarName {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
}
@media (max-width: 1440px) {
  .avaliationList li {
    text-align: start;
  }

  .avaliationList p {
    width: 80%;
  }
}

@media (max-width: 980px) {
  .clientsContainer {
    height: 100%;
  }

  .clientSubtitle {
    text-align: center;
  }

  .avaliationList {
    flex-direction: column;
    margin-top: -30px;
  }

  .avaliationList li {
    margin-top: 80px;
  }

  .avaliationList p {
    width: 80%;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .clientsContainer {
    height: 100%;
    
  }

  .clientSubtitle {
    width: 80%;
    text-align: center;
  }

  .avaliationList {
    flex-direction: column;
    margin-top: -30px;
  }

  .avaliationList li {
    margin-top: 80px;
  }

  .avaliationList p {
    width: 80%;
    text-align: center;
  }
}

@media (max-width: 340px) {
 
  .avatar {
    margin-left: 20px;
  }
}

@media (max-width: 260px) {
  .clientTitle {
    font-size: 30px;
  }
}
