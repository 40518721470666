.headerContainer {
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  transition: 0.5s;
  justify-content: space-around;
  -webkit-box-shadow: 0px 7px 10px -3px rgba(148, 148, 148, 0.48);
  -moz-box-shadow: 0px 7px 10px -3px rgba(148, 148, 148, 0.48);
  box-shadow: 0px 7px 10px -3px rgba(148, 148, 148, 0.48);

}

.logo {
  width: 100px;
  margin-left: 5px;
  cursor: pointer;
}

.headerList {
  display: flex;
  list-style: none;
  flex-direction: row;
}

.headerList li, .headerList a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
  cursor: pointer;
text-decoration: none;
}



.headerList li:hover {
border-bottom: 2px solid #5ca9fb; 

}

.hamburgerMenu {
  display: none;
  font-size: 30px;
}

/* Hamburger menu Open */

.headerList.open {
  display: flex;
  flex-direction: column;
 align-items: center;
}

.headerContainer.open {
  height: 35%;
}

@media (max-width: 650px) {
  .headerList {
    display: none;
  }

  .hamburgerMenu {
    display: block;
  }
}
