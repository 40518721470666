.servicesContainer {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #01162A;
  width: 100%;
  margin-top: 140px;
  align-items: center;
  height: 1000px;
}

.servicesContainer h1 {
  margin-top: 35px;
  font-size: 40px;
}

.servicesSubtitle {
  font-size: 19px;
  margin-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.icon {
  background-image: linear-gradient(to right, #01162A, #1d61dd);
  width: 150px;
  border-radius: 50%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
  -webkit-box-shadow: 6px 11px 40px -7px rgba(0, 0, 0, 0.76);
  -moz-box-shadow: 6px 11px 40px -7px rgba(0, 0, 0, 0.76);
  box-shadow: 6px 11px 40px -7px rgba(0, 0, 0, 0.76);
}

.servicesList {
  display: flex;
  list-style: none;
  align-items: center;

  justify-content: center;
}

.servicesList li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicesList p {
  max-width: 500px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
 
}

.servicesList div,
h3,
p {
  margin: 13px 100px;
}

/* RESPONSIVITY */
@media (max-width:1900px){
  .servicesContainer {
    height: 100%;
  }
}
@media (max-width: 1440px) {
  .servicesContainer {
    height: 100%;
  }

  .servicesList p,
  h3 {
    margin: 13px 50px;
  }

  .servicesList p {
    max-width: 450px;
    text-align: center;

  }
}

@media (max-width: 1100px) {
  .servicesContainer {
    height: 100%;
  }

  .servicesList li {
    display: flex;
    justify-content: center;
    max-width: 40%;
 
  }

  .servicesList p,
  h3 {
    margin: 13px 30px;
  }
}
@media (max-width: 1000px) {
  .servicesContainer {
    height: 100%;
    margin-top: 200px;
  }

  .servicesSubtitle {
    width: 100px;
    text-align: center;
    width: 90%;
  }

  .servicesList {
    display: flex;
    flex-wrap: wrap;
  }

  .servicesList li {
    margin-top: 30px;
    max-width: 90%;
  }

  .servicesList h3 {
    text-align: center;
  }

  .servicesList p {
    width: 90%;
    font-size: 18px;
  }

  .icon {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 500px) {
  .servicesContainer {
    margin-top: 150px;
    padding-bottom: 30px;
    height: 100%;
  }

.servicesContainer h1{
  font-size: 35px;
}

  .servicesSubtitle {
    width: 100px;
    text-align: center;
    width: 90%;
  }

  .servicesList {
    display: flex;
    flex-wrap: wrap;
  }

  .servicesList li {
    margin-top: 20px;
    max-width: 80%;
  }

  .servicesList h3 {
    text-align: center;
  }

  .servicesList p {
    width: 80%;
    font-size: 15px;
  }

  .icon {
    width: 100px;
    height: 100px;
  }
}

