.aboutContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  height: 750px;
  align-items: center;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.imageTeaser {
  width: 800px;
  height: 600px;
}

.aboutTitle {
  margin: 0;
  margin-bottom: 20px;
}

.aboutTitle::after {
  display: flex;
  content: "";
  background: linear-gradient(to right, #01162a 0%, #1d61dd 100%);
  height: 5px;
  width: 60px;
}

.aboutContent {
  margin-left: 100px;
}

.aboutContent p {
  margin: 0;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: justify;
  max-width: 600px;
hyphens: auto;
  
}



.aboutListContainer h3 {
  margin: 0;
  display: flex;
  align-self: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
}

.aboutList {
  display: flex;
  justify-content: space-evenly;
}

.whyChooseList {
  list-style: none;
}

.whyChooseList li {
  font-size: 20px;
  margin-top: 5px;
}

.aboutIcon {
  margin-right: 5px;
  color: #01162a;
}

@media (max-width: 1550px) {
  .imageTeaser {
    width: 700px;
    height: 525px;
  }

  .aboutContent {
    display: flex;
    flex-direction: column;
  }

  .aboutContent p {
    font-size: 18px;
    max-width: 525px;
  }

  .whyChooseList li {
    font-size: 18px;
  }
}

@media (max-width: 1350px) {
  .imageTeaser {
    width: 600px;
    height: 500px;
  }

  .aboutContent p {
    font-size: 18px;
    max-width: 500px;
  }

  .whyChooseList li {
    font-size: 18px;
  }
}

@media (max-width: 1250px) {
  .imageTeaser {
    width: 550px;
    height: 500px;
  }

  .aboutContent p {
    font-size: 17px;
    max-width: 400px;
  }

  .whyChooseList li {
    font-size: 17px;
  }
}

@media (max-width: 1150px) {
  .aboutContainer {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding-top: 170px;

    height: 950px;
  }

  .aboutListContainer {
    margin-bottom: 30px;
  }

  .aboutContent {
    margin: 0;
    margin-top: 30px;
  }

  .aboutContent p {
    font-size: 17px;
    max-width: 570px;
  }

  .whyChooseList li {
    margin-top: 5px;
    font-size: 17px;
  }
}

@media (max-width: 800px) {
  .aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 900px;
  }

  .aboutListContainer {
    margin-bottom: 0px;
  }

  .aboutContent {
    margin: 0;
    margin-top: 30px;
  }

  .aboutContent p {
    font-size: 17px;
    max-width: 550px;
  }

  .whyChooseList {
    margin-bottom: 30px;
  }

  .whyChooseList li {
    margin-top: 5px;
    font-size: 17px;
    margin-bottom: 10px;
  }
}

@media (max-width: 650px) {
  .aboutContainer {
    height: 900px;
  }
}

@media (max-width: 580px) {
  .aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 900px;
  }

  .imageTeaser {
    width: 400px;
  }

  .aboutContent {
    margin: 0;
    margin-top: 30px;
  }

  .aboutContent p {
    display: flex;
    align-self: center;
    font-size: 17px;
    max-width: 400px;
  }

  .whyChooseList li {
    margin-top: 5px;
    font-size: 17px;
    margin-right: 10px;
  }
}

@media (max-width: 500px) {
  .aboutContainer {
    height: 950px;
  }

  .imageTeaser {
    width: 350px;
    height: 250px;
  }

  .aboutContent {
    margin: 0;
    margin-top: 30px;
  }
  .aboutListContainer h3,
  .aboutContent h1 {
    margin-left: 13px;
  }

  .aboutContent p {
    display: flex;
    align-self: center;
    font-size: 16px;
    max-width: 350px;
  }

  .whyChooseList li {
    margin-top: 5px;
    font-size: 16px;
  }
}

@media (max-width: 440px) {
  .aboutContainer {
    height: 1000px;
  }
  .aboutContent {
    max-width: 300px;
  }

  .imageTeaser {
    width: 300px;
  }

  .aboutListContainer {
    margin-bottom: 20px;
  }

  .aboutListContainer h3,
  .aboutContent h1 {
    margin-left: 0;
  }

  .aboutList {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  .whyChooseList li {
    margin: 0;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-size: 16px;
  }

  .aboutContent p {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .aboutContainer {
    height: 100%;
  }
  .aboutContent {
    max-width: 250px;
  }

  .aboutContent .imageTeaser {
    width: 250px;
  }

  .aboutListContainer h3 {
    margin-bottom: 5px;
  }

  .aboutList {
    margin-left: 10px;
  }

  .whyChooseList {
    width: 300px;

    margin: 0px 10px;
  }

  .whyChooseList li {
    margin: 0;
    margin-top: 15px;
  }
}

@media (max-width: 300px) {
  .aboutContainer {
    height: 100%;
  }
  .aboutContent {
    max-width: 200px;
  }

  .aboutContent p {
    text-align: justify;
    
  }

  .imageTeaser {
    width: 200px;
  }

  .aboutListContainer h3 {
    margin-bottom: 5px;
  }

  .aboutList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .whyChooseList {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 0px;
  }

  .whyChooseList li {
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 15px;
    font-size: 15px;
  }
}
