.formContainer {
  height: 930px;
  background-color: #01162a;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
}

.formTitle {
  font-weight: 800;
  font-size: 40px;
}

.formContact {
  display: flex;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 100px;
}

.formSubtitle{
  font-family: Arial, Helvetica, sans-serif;

}

.textArea {
  height: 100px;
}

.textArea,
.input {
  display: flex;
  border: none;
  margin-top: 20px;
  border-radius: 5px;
  width: 800px;
  padding: 20px;
}

.inputSubmit {
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to right, #1d61dd 0%, #01162a 100%);
  color: white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 40px;
}

.contact h3 {
  margin-top: 30px;
}

.contact ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
}

.contact li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact p {
  display: flex;
  flex-direction: row;
}

.pin {
  margin-right: 10px;
}

.socialMedia ul {
  display: flex;
  flex-direction: row;
  list-style: none;

  font-size: 50px;
  cursor: pointer;
}


.socialMediaLink{
  text-decoration: none;
  color: white;
  margin: 10px 20px;
  transition: 0.3s;
}


.socialMediaLink:hover {
  transition: 0.3s;
  color: #5ca9fb;
}

@media (max-width: 1400px) {
  .formContainer {
    padding-top: 50px;
    height: 1100px;
  }

  .formContact {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .textArea,
  .input {
    width: 600px;
  }

  .contact {
    border: none;
    text-align: center;
  }



}

@media (max-width: 1300px) {
  .formContainer {
    padding-top: 50px;
    height: 1100px;
  }

  .formSubtitle {
    width: 80%;
    text-align: center;
  }

  .formTitle {
    margin-top: -10px;
  }

  .formContact {
    display: flex;
    flex-direction: column;
  }

  .textArea,
  .input {
    width: 260%;
  }

  .contact {
    border: none;
    padding: 0;
    text-align: center;
  }

  .contact li {
    display: flex;
    align-items: center;
  }
  .contactList p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
  }
}


@media (max-width: 900px) {
  .formContainer {
    padding-top: 50px;
    height: 1100px;
  }

  .formSubtitle {
    width: 80%;
    text-align: center;
  }

  .formTitle {
    margin-top: -10px;
  }

  .formContact {
    display: flex;
    flex-direction: column;
  }

  .textArea,
  .input {
    width: 260%;
  }

  .contact {
    border: none;
    padding: 0;
    text-align: center;
  }

  .contact li {
    display: flex;
    align-items: center;
  }
  .contactList p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
  }
}

@media (max-width: 550px) {
  .formContainer {
    padding-top: 50px;
    height: 1100px;
  }

  .formSubtitle {
    width: 80%;
    text-align: center;
  }

  .formTitle {
    margin-top: -10px;
  }

  .formContact {
    display: flex;
    flex-direction: column;
  }

  .textArea,
  .input {
    width: 160%;
  }

  .contact {
    border: none;
    padding: 0;
    text-align: center;
  }

  .contact li {
    display: flex;
    align-items: center;
  }
  .contactList p {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 300px;
  }
}

@media (max-width: 320px) {
  .textArea,
  .input {
    width: 250%;
  }
}
