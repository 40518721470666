.container {
  background-image: url("../assets/imageTeaserHome.jpg");
  background-size: 100% 100%;
  height: 1100px;
  display: flex;
  color: black;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.container h1 {
  margin: 0;
  font-size: 65px;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  text-align: center;
}

.container p {
  margin: 0;
  font-size: 25px;
  max-width: 70%;
  margin-top: 20px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.container button {
  margin-top: 50px;
  border: none;
  border-radius: 25px;
  width: 150px;
  height: 50px;
  background-image: linear-gradient(to left, #01162a, #1d61dd);
  color: white;
  font-size: 25px;
  letter-spacing: 2px;
  font-family: Helvetica, sans-serif;
}
@media (max-width: 900px) {
  .container h1 {
    font-size: 50px;
    width: 90%;
  }

  .container p {
    font-size: 19px;
    max-width: 90%;
  }
}

@media (max-width: 550px) {
  .container h1 {
    font-size: 45px;
    width: 90%;
  }

  .container p {
    font-size: 20px;
    max-width: 90%;
  }
}


@media (max-width: 290px){
  .container h1{ 
    font-size: 35px;
  
  }

  .container p{
    font-size: 18px;
  }
}